<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'NaturalPerson', title: 'Фізичні особи' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_natural_person)"
        :modal="show_natural_person_dialog"
        @updateItemModal="naturalPersonUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Список фізичних осіб
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openNaturalPersonCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити фізичну особу</span>
              </v-tooltip>

            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],
                }"
              class="custom-table custom-table-1"
              @click:row="onNaturalPersonItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon>
              <v-icon size="26" color="success" class="mr-2">
                mdi-format-list-numbered
              </v-icon>
            </template>
            <template v-slot:item.show_on_site="{ item }">
              <span>
                <v-simple-checkbox disabled :value="item.show_on_site"/>
              </span>
            </template>
            <template v-slot:item.management_group="{ item }">
              <span>
                {{ getManagementGroup(item.management_group) }}
              </span>
            </template>
            <template v-slot:item.date_of_birth="{ item }">
              <span>
                {{ item.date_of_birth | formatDate }}
              </span>
            </template>
            <template v-slot:item.create_date="{ item }">
              <span>
                {{ item.create_date | formatDate }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_NATURAL_PERSON} from "@/store/actions/response_people";
import {getManagementGroup} from "@/utils/icons"

export default {
  props: ['selectable', 'model'],
  name: "PositionView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getNaturalPeople'
    })
  },
  data() {
    return {
      selected: [],
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'ПІБ', value: 'full_name' },
        { text: 'Група упрвління', value: 'management_group' },
        { text: 'На сайт', value: 'show_on_site' },
        { text: 'Дата народження', value: 'date_of_birth' },
        { text: 'Створено', value: 'create_date' },
        { text: 'Автор', value: 'owner_name' },
      ],
      selected_natural_person: {},
      show_natural_person_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_NATURAL_PERSON
    }),
    getManagementGroup,
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    naturalPersonUpdateItemModal() {
      this.show_natural_person_dialog = false
      this.selected_natural_person = {}
    },
    openNaturalPersonCreateDialog() {
      this.selected_natural_person = {}
      this.show_natural_person_dialog = true
    },
    onNaturalPersonItemClick(payload) {
      this.selected_natural_person = JSON.parse(JSON.stringify(payload))
      this.show_natural_person_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>